import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HttpApiService {
    baseUrl = 'https://api.myslog.in/';
    dataAPI: any = {};

    constructor(
        private _http: HttpClient
    ) {
        this.dataAPI = {
            login: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/login`, data);
            },

            // All API_Roles
            apiUserRole: () => {
                return this.getAjaxMethod(`${this.baseUrl}api/apiroles/getall`);
            },

            // Add API User
            apiUserSave: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/apiusers/save`, data);
            },
            apiUserGetById: (id: number) => {
                return this.getAjaxMethod(`${this.baseUrl}api/apiusers/getbyid/${id}`);
            },
            apiUserPageList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/apiusers/apiusers_PageList`, data);
            },
            allAPIUser: () => {
                return this.getAjaxMethod(`${this.baseUrl}api/apiusers/getall`);
            },

            // All Business Master API
            business_save: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/business_master/save`, data);
            },
            generateBusinessLogin: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/business_master/GenerateLogin/${data.forsrno}-${data.passcode}-${data.loginname}-${data.fullname}-${data.password}-${data.apirole}`, '');
            },
            businessPageList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/business_master/business_master_PageList`, data);
            },
            businessGetById: (userSrNo: number) => {
                return this.getAjaxMethod(`${this.baseUrl}api/business_master/getbyid/${userSrNo}`);
            },

            // All Project Master API
            project_save: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_master/save`, data);
            },
            projectGetById: (projectId: number) => {
                return this.getAjaxMethod(`${this.baseUrl}api/project_master/getbyid/${projectId}`);
            },
            projectPageList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_master/project_master_PageList`, data);
            },
            projectGetAll: () => {
                return this.getAjaxMethod(`${this.baseUrl}api/project_master/getall`);
            },
            projectOnlyName: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_master/onlyname`, data);
            },

            // Add Project User
            projectUserSave: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_users/save`, data);
            },
            projectUserGetById: (srno: number) => {
                return this.getAjaxMethod(`${this.baseUrl}api/project_users/getbyid/${srno}`);
            },
            projectGetAllUser: (projectsrno: number) => {
                return this.getAjaxMethod(`${this.baseUrl}api/project_users/getall/${projectsrno}`);
            },
            projectUserPageList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_users/project_users_PageList`, data);
            },

            // Add Project Flow
            projectFlowSave: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_flow/save`, data);
            },
            projectFlowGetById: (flowId: number) => {
                return this.getAjaxMethod(`${this.baseUrl}api/project_flow/getbyid/${flowId}`);
            },
            projectFlowPageList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_flow/project_flow_PageList`, data);
            },

            // Add Project Work
            projectWorkSave: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_works/save`, data);
            },
            projectWorkGetById: (projectId: number) => {
                return this.getAjaxMethod(`${this.baseUrl}api/project_works/getbyid/${projectId}`);
            },
            projectWorkPageList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_works/project_works_PageList`, data);
            },
            myProjectWorkList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_works/mywork_PageList`, data);
            },

            // Add Project Excel
            projectExcelSave: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/excel_view/save`, data);
            },
            projectExcelGetById: (projectId: number) => {
                return this.getAjaxMethod(`${this.baseUrl}api/excel_view/getbyid/${projectId}`);
            },
            projectExcelPageList: (note: string, sp: string, date: string, data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/excel_view/excel_view_PageList/${note}-${sp}-${date}`, data);
            },
            projectExcelStatusPageList: (note: string, sp: string, date: string, data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/excel_view/excel_view_status/${note}-${sp}-${date}`, data);
            },
            myProjectExcelList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/excel_view/mywork_PageList`, data);
            },
            myProjectExcelProduct: () => {
                return this.postAjaxMethod(`${this.baseUrl}api/excel_view/std_projects`, '');
            },

            // Add Project Excel User Logs
            projectExcelUserLogSave: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/excel_user_logs/save`, data);
            },
            projectExcelUserLogPageList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/excel_user_logs/excel_user_logs_PageList`, data);
            },

            // Add Project Work User
            projectWorkUserSave: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_work_users/save`, data);
            },
            projectWorkUserGetById: (projectWorkId: number) => {
                return this.getAjaxMethod(`${this.baseUrl}api/project_work_users/getbyid/${projectWorkId}`);
            },
            projectWorkUserPageList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_work_users/project_work_users_PageList`, data);
            },

            //Add project work user response
            projectWorkResponseSave: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_work_users_response/save`, data);
            },
            getMyProjectWorkResponse: (projectworksrno: number) => {
                return this.getAjaxMethod(`${this.baseUrl}api/project_work_users_response/getmyresponse/${projectworksrno}`);
            },

            // Add Project Idea
            projectWorkIdeaSave: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_ideas/save`, data);
            },
            projectWorkIdeaGetById: (projectId: number) => {
                return this.getAjaxMethod(`${this.baseUrl}api/project_ideas/getbyid/${projectId}`);
            },
            projectWorkIdeaPageList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_ideas/project_ideas_PageList`, data);
            },

            // Add Project API
            projectApiSave: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_apis/save`, data);
            },
            projectApiGetById: (projectId: number) => {
                return this.getAjaxMethod(`${this.baseUrl}api/project_apis/getbyid/${projectId}`);
            },
            projectApiPageList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_apis/project_apis_PageList`, data);
            },
            myProjectApiPageList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/project_apis/my_project_apis_PageList`, data);
            },


            // Add Facility & Error API
            facilityErrorSave: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/facility_n_error/save`, data);
            },
            facilityErrorPageList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/facility_n_error/facility_n_error_PageList`, data);
            },
            facilityErrorGetById: (srno: number) => {
                return this.getAjaxMethod(`${this.baseUrl}api/facility_n_error/getbyid/${srno}`);
            },
            withinFacilityPageList: (data: any) => {
                return this.postAjaxMethod(`${this.baseUrl}api/facility_n_error/within_facility`, data);
            },


        };
    }

    getAjaxMethod(url: any) {
        return this._http.get(url);
    }

    postAjaxMethod(url: any, data: any) {
        return this._http.post(url, data);
    }
}
